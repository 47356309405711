/* App.css */

body {
  background-image: url('../src/assets/background.png');
  background-color: #292b2e;
  color: #ffffff;
  }

  body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  
  /* .score-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  } */
  
  .button-container {
    position: relative;
    z-index: 1;
  }
  
  
  .app-title {
  color: #ffffff;
  margin-top: 2rem;
  }

  .options {
    font-size: 28px;
  } 

  .submit {
    margin-top: 10px;
  }
  .navbar-button {
    color: white !important;
  }


.multiline-text {
    width:100%;
  }

  .contactform-submit {
    margin-top:40px;
  }

  /* About us styles -start*/

  .column {
    float: left;
    width: 33.3%;
    margin-bottom: 16px;
    padding: 0 8px;
  }
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
  }
  
  .about-section {
    padding: 50px;
    text-align: center;
    width: 100%;
    height:100vh;
    background-color: #495565;
    color: white;
  }
  
  .container {
    padding: 0 16px;
  }
  
  .container::after, .row::after {
    content: "";
    clear: both;
    display: table;
  }
  
  .title {
    color: grey;
  }
  
  .button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
  }

  .finalscreen {
    font-size: 28px;
    font-style: italic;
  }
  
  .button:hover {
    background-color: #555;
  }
  
  @media screen and (max-width: 650px) {
    .column {
      width: 100%;
      display: block;
    }
  }

   /* About us styles -end*/

   /* setting style - start */
   .MuiInputBase-formControl {
    width:100% !important;
    }

/*setting style - end */


  /* home styles -start */

  .filters {
    font-style: italic; 
    margin-bottom: 20px;
  }

  .question-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    max-width: 900px;
    width:400px;
    height:500px;
    margin:0 auto;
    position:absolute;
    left:50%;
    top:50%;
    margin-left:-170px;
    margin-top:-250px;
    font-family: 'Gabriola';
    font-size:25px;
  }

  .question-container p {
    text-align: center;
  }

  .question-container ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 10px;
  }

  .question-container li {
    cursor: pointer;
    background-color: rgb(179, 71, 31);
    color: rgb(16, 16, 16);
    text-align: center;
    line-height: 1.3;
    padding: 0.3em;
    width: 80%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    margin-top: 0.5em;
    border: 1px solid #222;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    -webkit-appearance: none;
    appearance: none;
    background-color:white;
  }

  /* .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
  }
  
  .video-background {
    position: absolute;
    top: 50%;
    left: 50%; 
    width: 100%;
    height: 100%;
    object-fit:cover;
    transform: translate(-60%, -10%);
    z-index: -1;
  } */

  .question-container .row {
    display: flex;
    justify-content: space-between;
    margin-top: -70px;
  }

  .question-container .row li{
   margin-right: 10px;
   padding: 0.1em;
  }
  .question-container h3 {
  margin:unset; 
  }

  #questionIndex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .score-container, .loading {
      cursor: pointer;
      color: rgb(16, 16, 16);
      text-align: center;
      line-height: 1.3;
      padding: 0.3em;
      width: 90%;
      max-width: 100%;
      box-sizing: border-box;
      margin: 0;
      margin-top: 0.5em;
      box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
      -webkit-appearance: none;
      appearance: none;
     
      top:30%;
      position: absolute;
     
  }
  .loading {
    box-shadow: none;
   
  }

  li.correct {
    background-color: rgb(53, 212, 53);
  }
  
  li.selected:not(correct) {
    background-color: rgb(206, 58, 58);
  }
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button {
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 20px !important;
  }

  .card-container {
    display: inline-flex;
  }
  @media screen and (min-width:768px) {
    .card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  .card-component {
    margin-right: 50px;
  }
}

.card-component {
  margin-bottom: 30px;
}
  .noquestion-message {  
    margin: 0;
    margin-top: 10em;
    margin-left:30%;
  }

  /* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .contactform {
  width:90%;
  margin:auto;
  }

  .question-container {
    width: 400px;
    left:unset;
    right:unset;
    margin-left: 25px;
    }

    .question-container .row {
      margin-top: unset;
    }

}


  